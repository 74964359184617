import React from 'react'

const HomeIcon = ({ fill }) => {
	return (
		<svg id="layout-home-icon" xmlns="http://www.w3.org/2000/svg" width="28.245" height="26.467" viewBox="0 0 28.245 26.467">
			<g id="icon-base">
				<path fill={fill} d="M15.768,25.361V16.976H9.255v8.385H0V8.591H25.022v16.77ZM0,8.591,12.511,0,25.022,8.591Z" transform="translate(1.611 0.607)" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
			</g>
			<g id="icon-hover">
				<g id="group-1">
					<circle fill={fill} id="ellipse-1" cx="2.6" cy="17.8" r="2.7"/>
					<path fill={fill} id="ellipse-2" d="M8.3,9.2c1.5,0,2.7,1.2,2.7,2.7s-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7c0,0,0,0,0,0
						C5.6,10.4,6.8,9.2,8.3,9.2z"/>
					<circle fill={fill} id="ellipse-3" cx="8.3" cy="17.8" r="2.7"/>
					<circle fill={fill} id="ellipse-4" cx="8.3" cy="23.7" r="2.7"/>
				</g>
				<g id="group-2">
					<path fill={fill} id="ellipse-5" d="M14.1,9.2c1.5,0,2.7,1.2,2.7,2.7c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7
					C11.5,10.4,12.7,9.2,14.1,9.2z"/>
					<circle fill={fill} id="ellipse-6" cx="14.1" cy="17.8" r="2.7"/>
					<circle fill={fill} id="ellipse-7" cx="14.1" cy="23.7" r="2.7"/>
				</g>
				<g id="group-3">
					<path fill={fill} id="ellipse-10" d="M20,9.2c1.5,0,2.7,1.2,2.7,2.7s-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7c0,0,0,0,0,0
					C17.3,10.4,18.5,9.2,20,9.2z" />
					<circle fill={fill} id="ellipse-6" cx="20" cy="17.8" r="2.7" />
					<circle fill={fill} id="ellipse-3" cx="20" cy="23.7" r="2.7" />
					<circle fill={fill} id="ellipse-7" cx="25.6" cy="17.8" r="2.7" />
				</g>
			</g>
		</svg>
	)
}

export default HomeIcon
