import React from 'react'

const RainbowIcon = ({ fill }) => {
	return (
		<svg id="layout-rainbow-icon" xmlns="http://www.w3.org/2000/svg" width="42.467" height="21.233" viewBox="0 0 42.467 21.233">
			<g id="icon-base" transform="translate(1.5 1.5)">
				<path d="M1505.19,86.677a19.734,19.734,0,0,1,39.467,0" transform="translate(-1505.19 -66.944)" fill="none" stroke={fill} strokeMiterlimit="10" strokeWidth="3" />
				<path d="M1511.629,89.394a16.011,16.011,0,0,1,32.023,0" transform="translate(-1507.907 -69.661)" fill="none" stroke={fill} strokeMiterlimit="10" strokeWidth="3" />
				<path d="M1518.032,92.1a12.31,12.31,0,0,1,24.621,0" transform="translate(-1510.608 -72.362)" fill="none" stroke={fill} strokeMiterlimit="10" strokeWidth="3" />
				<path d="M1518.032,92.1a12.31,12.31,0,0,1,24.621,0" transform="translate(-1510.608 -72.362)" fill="none" stroke={fill} strokeMiterlimit="10" strokeWidth="3" />
			</g>
			<g id="icon-hover">
				<path fill={fill} d="M5.5,13c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1s-4.1-1.8-4.1-4.1c0,0,0,0,0,0C1.4,14.8,3.2,13,5.5,13z"/>
				<path fill={fill} id="ellipse-1" d="M11,4.7c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1c-2.3,0-4.1-1.8-4.1-4.1c0,0,0,0,0,0
					C6.9,6.5,8.7,4.7,11,4.7z"/>
				<path fill={fill} id="ellipse-2" d="M20.9,1.6c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1c-2.3,0-4.1-1.8-4.1-4.1c0,0,0,0,0,0
					C16.8,3.5,18.6,1.6,20.9,1.6z"/>
				<path fill={fill} id="ellipse-3" d="M30.8,4.7c2.3,0,4.1,1.8,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1c-2.3,0-4.1-1.8-4.1-4.1c0,0,0,0,0,0
					C26.7,6.5,28.5,4.7,30.8,4.7z"/>
				<path fill={fill} id="ellipse-4" d="M37,13c2.3,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1c-2.3,0-4.1-1.8-4.1-4.1c0,0,0,0,0,0
					C32.9,14.8,34.7,13,37,13z"/>
			</g>
		</svg>
	)
}

export default RainbowIcon
