import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios'
import AppContext from '../../context'
import './artwork.scss'

import Contact from './Contact'
import NextArtist from './NextArtist'
import Media from './Media'
import LocationIcon from '../Icons/LocationIcon'

const Artwork = props => {
	const { slug } = props.match.params
	const [artwork, setArtwork] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const { setDotsLoaded, innerMainRef } = useContext(AppContext)

	const artworkRef = useRef(null)

	useEffect(() => {
		innerMainRef.current.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		axios.get(`https://heartlands.pluralartmag.com/wp-json/wp/v2/artworks?slug=${slug}`).then(res => {
			setArtwork(res.data[0])
			setIsLoading(false)
			setDotsLoaded(true)
		})
	}, [slug])

	useEffect(() => {
		if (artworkRef.current) {
			setTimeout(() => {
				artworkRef.current.style.opacity = 1
				innerMainRef.current.focus()
			}, 600)	
		}
	}, [isLoading])

	return (
		<>
			{!isLoading ? 
				<div className="single-artwork">
					<div ref={artworkRef} className="container" style={{ opacity: 0 }}>
						<section className="artwork-meta">
							<span className="artwork-label">Artist</span>
							<h2 className="artwork-artist-name">{artwork.acf.artist_name}</h2>	
							{artwork.acf.site ? 
								<>
									<span className="artwork-label">At</span>
									<h3 className="artwork-location">
										{artwork.acf.site} &nbsp;
										{artwork.acf.site_locator ?
											<a href={artwork.acf.site_locator} target="_blank" rel="noopener noreferrer">
												<LocationIcon fill="#fff" />
											</a>
										: ''}
									</h3>
								</>
							: ''}
							<>
								<span className="artwork-label">Title</span>
								<h3 className="artwork-title">{artwork.acf.artwork_title}</h3>
							</>
							<>
								<span className="artwork-label">Medium / Size</span>
								<div className="artwork-medium" dangerouslySetInnerHTML={{ __html: artwork.acf.medium }}></div>
							</>
						</section>

						<Media mediaContent={artwork.acf.artwork_media} />

						<section className="artwork-about">
							<h3 className="artwork-section-title">About the Artwork</h3>
							<div className="artwork-content" dangerouslySetInnerHTML={{ __html: artwork.acf.about_artwork }}></div>
						</section>
						
						{artwork.acf.price ?
							<section className="artwork-price">
								<h3 className="artwork-section-title">Price</h3>
								<div className="artwork-content" dangerouslySetInnerHTML={{ __html: artwork.acf.price }}></div>
							</section>
						: ''}

						{artwork.acf.more_information ?
							<section className="artwork-more-information">
								<h3 className="artwork-section-title">Additional Information</h3>
								<div className="artwork-content" dangerouslySetInnerHTML={{ __html: artwork.acf.more_information }}></div>
							</section>
						: ''}

						{artwork.acf.artist_contact ? 
							<Contact socials={artwork.acf.artist_contact[0]} />
						: ''}

						<NextArtist id={artwork.next} artworkRef={artworkRef} />
					</div>
				</div>
				: <></>
			}
		</>
	)
}

export default Artwork