import React, { useEffect, useContext, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import AppContext from '../../context'
import './search.scss'
import PriceIcon from '../Icons/PriceIcon'
import SearchBar from './SearchBar'
import SortableTable from './SortableTable'

const Search = props => {
	const history = useHistory()
	const { setDotsLoaded } = useContext(AppContext)
	const [allArtworks, setAllArtworks] = useState([])
	const [keyword, setKeyword] = useState('')
	const [results, setResults] = useState([])
	const [isSearching, setIsSearching] = useState(false)
	const searchInputRef = useRef(null)

	useEffect(() => {
		axios.get('https://heartlands.pluralartmag.com/wp-json/wp/v2/artworks?per_page=100').then(res => {
			setAllArtworks(res.data)
		})
	}, [])

	const submitSearch = e => {
		e.preventDefault()
		setIsSearching(true)
		if (allArtworks.length) {
			setResults(allArtworks.filter(artwork => {
				return (
					artwork.acf.artist_name.toLowerCase().includes(keyword) ||
					artwork.acf.site.toLowerCase().includes(keyword) ||
					artwork.acf.artwork_title.toLowerCase().includes(keyword) ||
					artwork.acf.medium.toLowerCase().includes(keyword) ||
					artwork.acf.about_artwork.toLowerCase().includes(keyword) ||
					artwork.acf.more_information.toLowerCase().includes(keyword)
				)
			}))
		}
	}

	useEffect(() => {
		setDotsLoaded(true)
	}, [])

	const { items, sortColumn } = SortableTable(results)

	const hideSearch = () => {
		setResults([])
		setKeyword('')
		searchInputRef.current.value = ''
	}

	const goToArtwork = slug => {
		hideSearch()
		history.push(`/artworks/${slug}`)
	}

	return (
		<div className="search all-artists">
			<div className="container">
			<SearchBar 
				submitSearch={submitSearch} 
				setKeyword={setKeyword} 
				searchInputRef={searchInputRef} 
				isSearching={isSearching} 
				setIsSearching={setIsSearching} 
			/>
			{items && items.length ?
				<table className="artists-table search-table">
					<thead>
						<tr>
							<th><button onClick={() => sortColumn('artist_name')}>Artist</button></th>
							<th><button onClick={() => sortColumn('artwork_title')}>Title</button></th>
							<th><button onClick={() => sortColumn('site')}>Site</button></th>
							<th><button onClick={() => sortColumn('for_sale')}>For sale</button></th>
						</tr>
					</thead>
					<tbody>
						{items.map((artwork, index) => (
							<tr key={artwork.id} onClick={() => goToArtwork(artwork.slug)}>
								<td className="artist-name">{artwork.acf.artist_name}</td>
								<td className="artwork-title">{artwork.acf.artwork_title}</td>
								<td>{artwork.acf.site}</td>
								<td>{artwork.acf.for_sale ? <PriceIcon fill="#fff" /> : ''}</td>
							</tr>	
						))}
					</tbody>
				</table>
			: null}
			{keyword !== '' && isSearching && !items ?
				<p style={{
					textAlign: 'center',
					color: '#fff',
					fontSize: '1.25rem'
				}}>No entries found for {keyword}</p>
			: null}
			</div>
		</div>
	)
}

export default Search