import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import HomeIcon from '../Icons/HomeIcon'
import GridIcon from '../Icons/GridIcon'
import RainbowIcon from '../Icons/RainbowIcon'
import CircleIcon from '../Icons/CircleIcon'

const LayoutSwitcher = () => {
	const location = useLocation()
	
	return (
		<div className="layout-switcher">
			<Link to="/"><HomeIcon fill={location.pathname === '/' ? '#540f3e' : '#fff'} /></Link>
			<Link to="/grid"><GridIcon fill={location.pathname === '/grid' ? '#025160' : '#fff'} /></Link>
			<Link to="/rainbow"><RainbowIcon fill={location.pathname === '/rainbow' ? '#ca5e73' : '#fff'} /></Link>
			<Link to="/jackpot"><CircleIcon fill={location.pathname === '/jackpot' ? '#ffaa00' : '#fff'} /></Link>
		</div>
	)
}

export default LayoutSwitcher