import React from 'react'

const PlayIcon = ({ fill }) => {
	return (
		<svg width="100%" height="100%" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg"fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
		<path d="M20,0C31.038,0 40,8.962 40,20C40,31.038 31.038,40 20,40C8.962,40 0,31.038 0,20C0,8.962 8.962,0 20,0ZM34.381,20L11.92,31.222L11.92,8.778L34.381,20Z" fill={fill} fillOpacity="0.7" />
		</svg>

	)
}

export default PlayIcon