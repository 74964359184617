import React from 'react'

const CircleIcon = ({ fill }) => {
	return (
		<svg id="layout-jackpot-icon" xmlns="http://www.w3.org/2000/svg" width="25.022" height="25.022" viewBox="0 0 25.022 25.022">
			<g id="icon-base">
				<circle fill={fill} cx="12.5" cy="12.5" r="12.5"/>
			</g>
			<g id="icon-hover">
				<polygon id="shape-a" fill={fill} points="12.4,0 1.2,25 23.9,25 12.6,0 "/>
				<path fill={fill} id="shape-r" d="M18.5,14.8c0.7-0.9,1.2-1.8,1.6-2.9c0.3-1,0.5-2.1,0.5-3.2c0-1.2-0.2-2.5-0.7-3.6c-0.4-1.1-1.1-2-2-2.7
		c-0.9-0.8-2-1.4-3.2-1.8C13.3,0.2,11.9,0,10.4,0H2.5v25h19.9l-6-8.5C17.2,16.1,17.9,15.5,18.5,14.8L18.5,14.8z" />
				<polygon fill={fill} id="shape-t" points="3.8,6.5 8.7,6.5 8.7,25 16.5,25 16.5,6.5 21.3,6.5 21.3,0 3.8,0" />
			</g>
		</svg>
	)
}

export default CircleIcon