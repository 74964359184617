import React, { useContext, useState, useEffect, useRef } from 'react'
import AppContext from '../../context'
import { Link, useHistory } from 'react-router-dom'
import { gsap, Back, Power3 } from 'gsap'
import debounce from '../../utils/debounce'
import flip from '../../utils/flip'
import './artworks.scss'

const Map = ({ artworks }) => {

	const [artworkSize, setArtworkSize] = useState(0)
	const [display, setDisplay] = useState('')
	const { dotsLoaded, setDotsLoaded } = useContext(AppContext)
	const history = useHistory()

	const dotNodes = [[], [], [], [], [], [], [], [], [], [], [], [], [], []]
	const gridBoxes = []

	gsap.config({
		nullTargetWarn: false
	})

	const setDotRef = ref => {
		if (ref && ref.getAttribute('data-row')) {
			let row = parseInt(ref.getAttribute('data-row'))
			dotNodes[row].push(ref)
		}
	}

	const rows = []
	if (artworks.length) {
		if (window.innerWidth > 640) {
			rows[0] = {
				start: 6,
				end: 10,
				artworks: artworks.slice(0, 5)
			}
			rows[1] = {
				start: 5,
				end: 11,
				artworks: artworks.slice(5, 12)
			}
			rows[2] = {
				start: 2,
				end: 14,
				artworks: artworks.slice(12, 25)
			}
			rows[3] = {
				start: 2,
				end: 15,
				artworks: artworks.slice(25, 39)
			}
			rows[4] = {
				start: 1,
				end: 16,
				artworks: artworks.slice(39, 55)
			}
			rows[5] = {
				start: 0,
				end: 16,
				artworks: artworks.slice(55, 72)
			}
			rows[6] = {
				start: 0,
				end: 15,
				artworks: artworks.slice(72, 88)
			}
			rows[7] = {
				start: 1,
				end: 12,
				gaps: [2, 3, 4, 5],
				artworks: artworks.slice(88, 96)
			}
			rows[8] = {
				start: 7,
				end: 11,
				artworks: artworks.slice(96, 100)
			}
		} else {
			rows[0] = {
				start: 9,
				end: 11,
				artworks: artworks.slice(0, 3)
			}
			rows[1] = {
				start: 5,
				end: 12,
				gaps: [8, 9],
				artworks: artworks.slice(3, 9)
			}
			rows[2] = {
				start: 4,
				end: 12,
				gaps: [9, 10, 11],
				artworks: artworks.slice(9, 15)
			}
			rows[3] = {
				start: 3,
				end: 11,
				artworks: artworks.slice(15, 24)
			}
			rows[4] = {
				start: 1,
				end: 12,
				artworks: artworks.slice(24, 36)
			}
			rows[5] = {
				start: 1,
				end: 13,
				artworks: artworks.slice(36, 49)
			}
			rows[6] = {
				start: 0,
				end: 13,
				artworks: artworks.slice(49, 63)
			}
			rows[7] = {
				start: 0,
				end: 12,
				artworks: artworks.slice(63, 76)
			}
			rows[8] = {
				start: 1,
				end: 9,
				gaps: [2, 3, 4],
				artworks: artworks.slice(76, 82)
			}
			rows[9] = {
				start: 0,
				end: 8,
				gaps: [1, 2, 4, 5],
				artworks: artworks.slice(82, 87)
			}
			rows[10] = {
				start: 1, 
				end: 4,
				artworks: artworks.slice(87, 91)
			}
			rows[11] = {
				start: 1,
				end: 8,
				gaps: [5, 7],
				artworks: artworks.slice(91, 97)
			}
			rows[12] = {
				start: 2,
				end: 7,
				gaps: [4, 5, 6],
				artworks: artworks.slice(97, 100)
			}
		}
	}

	useEffect(() => {
		changeDotSize()
	}, [])

	useEffect(() => {
		if (artworks && gridBoxes && dotsLoaded) {
			flip(dotNodes.flat(), spreadToMap, { duration: 1.25, ease: Back.easeInOut })
		}
	}, [artworks, dotsLoaded])

	const changeDotSize = () => {
		if (window.innerWidth > 640) setArtworkSize(0.0375 * window.innerWidth)
		else setArtworkSize(0.055 * window.innerWidth)
	}

	const spreadToMap = () => {
		let count = 0
		dotNodes.forEach((row, rowIndex) => {
			row.forEach((dot, index) => {
				let start = parseInt(dot.getAttribute('data-start'))
				let end = parseInt(dot.getAttribute('data-end'))
				let col = parseInt(dot.getAttribute('data-col'))
				let gaps = dot.getAttribute('data-gaps')
				if (gaps !== '') {
					gaps = gaps.split(',').map(item => parseInt(item))
				}
				let gridPos = calculateGridPosition(rowIndex, start + col, gaps)
				let mapParent = gridBoxes[gridPos]
				mapParent.appendChild(dot)
				count++
			})
		})
	}

	const renderGrid = () => {
		let grid = new Array(window.innerWidth < 640 ? 182 : 153).fill(undefined)
		const gridLayout = grid.map((box, i) => (
			<div 
				className="grid-box" 
				key={i} 
				data-position={i} 
				ref={e => {gridBoxes[i] = e}} 
				style={{ 
					width: artworkSize, 
					height: artworkSize 
				}}
			></div>	
		))
		return gridLayout
	}

	const imageCounter = useRef(0)
	const imageLoaded = () => {
		imageCounter.current += 1
		if (imageCounter.current >= artworks.length) {
			setDotsLoaded(true)
		}
	}

	const goToArtwork = (slug, row, index) => {
		dotNodes[row][index].style.zIndex = 3
		gsap.to(dotNodes[row][index], 2, {
			scale: 80,
			backgroundColor: '#a79f9d',
			ease: Power3.easeInOut, 
			onStart: () => {
				dotNodes[row][index].childNodes[0].style.opacity = 0
			},
			onComplete: () => {
				history.push(`/artworks/${slug}`)
			}
		})
	}

	return (
		<div className="all-artworks artworks-map">
			<div className="temp-dot" style={{ width: artworkSize, height: artworkSize, display: dotsLoaded ? 'none' : 'block' }}></div>
			{rows.map((row, rowIndex) => row.artworks.map((artwork, index) => {
				let thumbnail = artwork.better_featured_image ? artwork.better_featured_image.media_details.sizes.thumbnail.source_url : ''	
				return (
					<div
						onClick={() => goToArtwork(artwork.slug, rowIndex, index)}
						key={index}
						ref={setDotRef}
						className="artwork-dot"
						data-row={rowIndex} 
						data-start={row.start}
						data-end={row.end}
						data-gaps={row.gaps ? row.gaps.toString() : ''}
						data-col={index}
						style={{
							display: dotsLoaded ? 'block' : 'none',
							width: artworkSize, 
							height: artworkSize,
							backgroundColor: '#fff'
						}}
					>
						<img src={thumbnail} onLoad={imageLoaded} />
					</div>
				)
			}))}
			<div className="invisible-grid map">
				{renderGrid()}
			</div>
		</div>
	)
}

// Calculate position in grid for mapview
const calculateGridPosition = (row, col, gaps = []) => {
	let multiplier = window.innerWidth < 640 ? 14 : 17
	if (gaps.length === 0) {
		return (row * multiplier) + col
	} else {
		gaps = gaps.map(gap => (row * multiplier) + gap)
		let pos = (row * multiplier) + col
		let oldPos = pos
		if (gaps.includes(pos)) {
			if (multiplier === 14) {
				while (gaps.includes(pos)) {
					if (row === 8) {
						pos = pos + gaps.length
					} else {
						pos = pos + 1 + gaps.indexOf(oldPos)
					}
				}
				let idx = gaps.indexOf(oldPos)
				let prevPos = idx > 0 ? gaps[idx - 1] : null
				if (prevPos !== null && oldPos - prevPos > 1) {
					return pos + 1
				} else {
					return pos
				}
			} else {
				while (gaps.includes(pos)) {
					pos = pos + gaps.length
				}
				return pos
			}
		}
		if (pos < Math.min(...gaps)) {
			return pos
		} else {
			return pos + gaps.length
		}
	}
}

export default Map