import { useState, useMemo } from 'react'

const SortableTable = (items, config = null) => {
	const [sortConfig, setSortConfig] = useState(config)

	const sortedItems = useMemo(() => {
		if (items && items.length) {
			let sortableItems = [...items]
			if (sortConfig !== null) {
				sortableItems.sort((a, b) => {
					if (a.acf[sortConfig.key] < b.acf[sortConfig.key]) {
						return sortConfig.direction === 'asc' ? -1 : 1
					} else if (a.acf[sortConfig.key] > b.acf[sortConfig.key]) {
						return sortConfig.direction === 'asc' ? 1 : -1
					} else {
						return 0
					}
				})
			}
			return sortableItems
		}
	}, [items, sortConfig])

	const sortColumn = key => {
		let direction = 'asc'
		if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc'
		}
		setSortConfig({ key, direction })
	}

	return { items: sortedItems, sortColumn, sortConfig }
}

export default SortableTable