import React, { useEffect, useState } from 'react'
import Thumbnail from './Thumbnail'

const Media = props => {
	const { mediaContent } = props
	const [content, setContent] = useState([])

	useEffect(() => {
		if (mediaContent.length > 1) {
			let temp = []
			temp[0] = mediaContent.filter((c, i) => !(i % 2))
			temp[1] = mediaContent.filter((c, i) => i % 2)
			setContent(temp)
		}
	}, [mediaContent])

	return (
		<>
			{mediaContent.length ? 
				<section className="artwork-media">
					{mediaContent.length === 1 ? 
						<div className={mediaContent[0]._description ? 'media-wrapper media-image-description-centered' : 'media-wrapper media-image-only'}>
							<Thumbnail media={mediaContent[0]} size="large" />
							{mediaContent[0]._description ?
								<div className="media-description">
									<p>{mediaContent[0]._description}</p>
								</div>
							: ''}
						</div>
					:
						<div className="media-wrapper media-grid">
							{content.map((col, colIndex) => {
								if (Array.isArray(col)) {
									return (
										<div className="media-col" key={colIndex}>
											{col.map((media, index) => (
												<div key={index} className="media-content">
													{media._description ? 
														<div className="media-description">{media._description}</div>
													: ''}
													{media._thumbnail ? 
														<Thumbnail media={media} size="large" />
													: ''}
												</div>
											))}
										</div>
									)
								}
							})}
						</div>
					}
				</section>
			: ''}
		</>
	)
}

export default Media