import React, { useContext, useEffect, useRef, useState } from 'react'
import { Switch, useHistory, useLocation, Route } from 'react-router-dom'
import AppContext from '../../context'
import Map from './Map'
import Grid from './Grid'
import Rainbow from './Rainbow'
import Jackpot from './Jackpot'

const Artworks = props => {
	const { artworks, mainRef, innerMainRef } = useContext(AppContext)
	const [random, setRandom] = useState({})
	const history = useHistory()
	const location = useLocation()
	const randomCounter = useRef(0)

	useEffect(() => {
		if (artworks.length && randomCounter.current < 1) {
			setRandom(artworks[Math.floor(Math.random() * artworks.length)])
			randomCounter.current += 1
		}
	}, [artworks])

	useEffect(() => {
		const unlisten = history.listen(() => {
			setTimeout(function() {
				innerMainRef.current.scrollTo(0, 0)
				mainRef.current.scrollTo(0, 0)
			}, 500)
		})
		return () => {
			unlisten()
		}
	}, [location, history])

	return (
		<Switch>
			<Route exact path="/" render={(props) => <Map {...props} artworks={artworks} />} />
			<Route exact path="/grid" render={(props) => <Grid {...props} artworks={artworks} />} />
			<Route exact path="/rainbow" render={(props) => <Rainbow {...props} artworks={artworks} />} />
			<Route exact path="/jackpot" render={(props) => <Jackpot {...props} artwork={random} />} />
		</Switch>
	)
}

export default Artworks