import React from 'react'

import WebsiteIcon from '../Icons/WebsiteIcon'
import FacebookIcon from '../Icons/FacebookIcon'
import InstagramIcon from '../Icons/InstagramIcon'
import EmailIcon from '../Icons/EmailIcon'

const Contact = props => {
	const socials = props.socials

	return (
		<>
			{socials ? 
				<section className="artist-contact">
					<ul className="social-links">
						{socials._website ? 
							<li>
								<a href={socials._website} target="_blank" rel="noopener noreferrer">
									<WebsiteIcon fill="#fff" />
								</a>
							</li>
						: ''}
						{socials._facebook ? 
							<li>
								<a href={socials._facebook} target="_blank" rel="noopener noreferrer">
									<FacebookIcon fill="#fff" />
								</a>
							</li>
						: ''}
						{socials._instagram ? 
							<li>
								<a href={socials._instagram} target="_blank" rel="noopener noreferrer">
									<InstagramIcon fill="#fff" />
								</a>
							</li>
						: ''}
						{socials._email ? 
							<li>
								<a href={`mailto:${socials._email}`} target="_blank" rel="noopener noreferrer">
									<EmailIcon fill="#fff" />
								</a>
							</li>
						: ''}
					</ul>	
				</section>
			: ''}
		</>
	)
}

export default Contact