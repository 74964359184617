import React, { useRef } from 'react'
import Video from './Video'

const Lightbox = ({ media, showLightbox, setShowLightbox }) => {

	const containerRef = useRef(null)
	const lightboxRef = useRef(null)

	const handleLightboxClick = e => {
		e.stopPropagation()
		if (e.target === containerRef.current || e.target === lightboxRef.current) {
			setShowLightbox(false)
		}
	}

	const getSize = thumbnail => {
		let ext = thumbnail.url.split(/[#?]/)[0].split('.').pop().trim()
		if (ext === 'gif') {
			return thumbnail.url
		}
		return thumbnail.sizes.large
	}

	return (
		<div ref={lightboxRef} className={showLightbox ? `lightbox lightbox-open` : `lightbox`} onClick={handleLightboxClick}>
			<div ref={containerRef} className="container">
				{media._video_url ?
					<Video src={media._video_url} />
					:
					<img src={getSize(media._thumbnail)} alt={media._thumbnail.alt} />
				}
			</div>
			<button className="lightbox-close" onClick={() => setShowLightbox(false)}>&times;</button>
		</div>
	)
}

export default Lightbox