import React, { useEffect, useContext, useState } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import AppContext from '../context'

import Home from './Home/Home'
import About from './Pages/About'
import Artists from './Pages/Artists'
import Search from './Pages/Search'
import Artwork from './Artwork/Artwork'

import mapBg from '../backgrounds/map-view-bg.jpg'
import gridBg from '../backgrounds/grid-view-bg.jpg'
import rainbowBg from '../backgrounds/rainbow-view-bg.jpg'
import jackpotBg from '../backgrounds/jackpot-view-bg.jpg'
import aboutBg from '../backgrounds/about-bg.jpg'
import artistsBg from '../backgrounds/artists-bg.jpg'
import artworkBg from '../backgrounds/artwork-bg.jpg'

const RouteTransitions = props => {
	const { mainRef, dotsLoaded, innerMainRef } = useContext(AppContext)
	const [mainBg, setMainBg] = useState('')
	const [mainClass, setMainClass] = useState('')
	const location = useLocation()
	const history = useHistory()

	useEffect(() => {
		if (location.pathname.includes('/artworks/')) {
			setTimeout(() => {
				setMainBg(artworkBg)
				setMainClass('main-artwork')
			}, 200)	
		}
		switch (location.pathname) {
			case '/':
				setMainBg(mapBg)
				setMainClass('main-map')
				break
			case '/grid':
				setMainBg(gridBg)
				setMainClass('main-grid')
				break
			case '/rainbow':
				setMainBg(rainbowBg)
				setMainClass('main-rainbow')
				break
			case '/jackpot':
				setMainBg(jackpotBg)
				setMainClass('main-jackpot')
				break
			case '/about':
				setMainBg(aboutBg)
				setMainClass('main-about')
				break
			case '/artists':
			case '/search':
				setMainBg(artistsBg)
				setMainClass('main-artists')
				break
			case '/artworks':
				setMainBg(artistsBg)
				setMainClass('main-artworks')
				break
			default:
				setMainBg(artworkBg)
		}
	}, [location])

	useEffect(() => {
		const unlisten = history.listen(() => {
			setTimeout(function() {
				innerMainRef.current.scrollTo(0, 0)
				mainRef.current.scrollTo(0, 0)
			}, 500)
		})
		return () => {
			unlisten()
		}
	}, [location, history])

	return (
		<main tabIndex="0" ref={mainRef} className={`app-main ${mainClass}`} style={{ 
			backgroundImage: dotsLoaded ? `url(${mainBg})` : '',
			backgroundColor: '#540f3e'
		}}>
			<div ref={innerMainRef} tabIndex="1">
				<TransitionGroup>
					<CSSTransition key={location.key} mountOnEnter={true} unMountOnExit={true} timeout={800} classNames="fade-up-down">
						<Switch location={location}>
							<Route exact path="/about" component={About} />
							<Route exact path="/artists" component={Artists} />
							<Route exact path="/search" component={Search} />
							<Route path="/artworks/:slug" component={Artwork} />
							<Route path="/" component={Home} />
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			</div>
		</main>
	)
}

export default RouteTransitions