import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import AppContext from '../../context'
import './artists.scss'

import SortableTable from './SortableTable'
import PriceIcon from '../Icons/PriceIcon'

const Artists = props => {
	const history = useHistory()
	const artistsRef = useRef(null)
	const [artworks, setArtworks] = useState([])
	const { setDotsLoaded } = useContext(AppContext)
	const { items, sortColumn } = SortableTable(artworks)

	useEffect(() => {
		artistsRef.current.focus()
		axios.get('https://heartlands.pluralartmag.com/wp-json/wp/v2/artworks?per_page=100').then(res => {
			res.data.sort((a, b) => a.acf.artist_name.toLowerCase() > b.acf.artist_name.toLowerCase() ? 1 : -1)
			setArtworks(res.data)
			setDotsLoaded(true)
		})
	}, [])
	return (
		<div className="all-artists" ref={artistsRef} tabIndex="2">
			{items && items.length ?
				<div className="container">
					<table className="artists-table">
						<thead>
							<tr>
								<th><button onClick={() => sortColumn('artist_name')}>Artist</button></th>
								<th><button onClick={() => sortColumn('artwork_title')}>Title</button></th>
								<th><button onClick={() => sortColumn('site')}>Site</button></th>
								<th><button onClick={() => sortColumn('for_sale')}>For sale</button></th>
							</tr>
						</thead>
						<tbody>
							{items.map((artwork, index) => (
								<tr key={artwork.id} onClick={() => history.push(`/artworks/${artwork.slug}`)}>
									<td className="artist-name">{artwork.acf.artist_name}</td>
									<td className="artwork-title">{artwork.acf.artwork_title}</td>
									<td className="site-name">{artwork.acf.site}</td>
									<td className="for-sale">{artwork.acf.for_sale ? <PriceIcon fill="#fff" /> : ''}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			: ''}
		</div>
	)
}

export default Artists
