import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios'
import AppContext from '../../context'
import './about.scss'
import Logo from '../Icons/Logo'

const About = props => {
	const [content, setContent] = useState('')
	const { dotsLoaded, setDotsLoaded } = useContext(AppContext)
	const aboutRef = useRef(null)

	useEffect(() => {
		aboutRef.current.focus()
		axios.get('https://heartlands.pluralartmag.com/wp-json/wp/v2/pages/169').then(res => {
			setContent(res.data.content.rendered)
			setDotsLoaded(true)
		})
	}, [])

	return (
		<div className="about-page" ref={aboutRef} tabIndex="2">
			<div className="container" style={{ maxWidth: '780px' }}>
				<Logo fill="#fff" attr={{ height: '30px' }} />
				<div dangerouslySetInnerHTML={{ __html: content }}></div>
			</div>
		</div>
	)
}

export default About
