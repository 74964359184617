import React from 'react'

const GridIcon = ({ fill }) => {
	return (
		<svg id="layout-grid-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="27" height="27"
		viewBox="0 0 27 27">
			<g transform="translate(1 1)" id="icon-base">
				<rect width="25" height="25" fill="none" stroke={fill} strokeMiterlimit="10" strokeWidth="2" />
				<line x1="0" y1="16.7" x2="25" y2="16.7" stroke={fill} strokeMiterlimit="10" strokeWidth="2" />
				<line x1="0" y1="8.3" x2="25" y2="8.3" stroke={fill} strokeMiterlimit="10" strokeWidth="2" />
				<line x1="16.7" y1="0" x2="16.7" y2="25" stroke={fill} strokeMiterlimit="10" strokeWidth="2" />
				<line x1="8.3" y1="0" x2="8.3" y2="25" stroke={fill} strokeMiterlimit="10" strokeWidth="2" />
			</g>
			<g id="icon-hover">
				<circle id="circle-1" cx="5.2" cy="5.2" r="3.1" fill={fill} />
				<circle id="circle-2" cx="5.2" cy="13.5" r="3.1" fill={fill} />
				<circle id="circle-3" cx="5.2" cy="21.9" r="3.1" fill={fill} />
				<circle id="circle-6" cx="13.5" cy="5.2" r="3.1" fill={fill} />
				<circle id="circle-5" cx="13.5" cy="13.5" r="3.1" fill={fill} />
				<circle id="circle-4" cx="13.5" cy="21.9" r="3.1" fill={fill} />
				<circle id="circle-9" cx="21.8" cy="21.9" r="3.1" fill={fill} />
				<circle id="circle-7" cx="21.8" cy="5.2" r="3.1" fill={fill} />
				<circle id="circle-8" cx="21.8" cy="13.5" r="3.1" fill={fill} />
			</g>
		</svg>
	)
}

export default GridIcon