import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import AppContext from '../../context'
import './footer.scss'
import SearchIcon from '../Icons/SearchIcon'

const Footer = () => {
	const location = useLocation()
	const { showSearch, setShowSearch } = useContext(AppContext)
	return (
		<footer>
			<div className="container small">
				<nav id="footer-nav">
					<ul className="menu">
						<li><Link to="/about" style={{ color: location.pathname === '/about' ? '#b80f20' : '#fff' }}>About</Link></li>
						<li><Link to="/artists" style={{ color: location.pathname === '/artists' ? '#b80f20' : '#fff' }}>Artists</Link></li>
						<li className="search-trigger">
							<Link to="/search" style={{ color: location.pathname === '/search' ? '#b80f20' : '#fff' }}>
							Search
							<SearchIcon fill={location.pathname === '/search' ? '#b80f20' : '#fff' } />
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</footer>
	)
}

export default Footer