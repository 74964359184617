import React, { useEffect, useContext } from 'react'
import axios from 'axios'
import Artworks from './Artworks'
import AppContext from '../../context'

const Home = props => {

	const { artworks, setArtworks, setDotsLoaded } = useContext(AppContext)

	useEffect(() => {
		axios.get('https://heartlands.pluralartmag.com/wp-json/wp/v2/artworks?per_page=100').then(res => {
			setArtworks(res.data)
		})
	}, [setDotsLoaded])

	return (
		<Artworks artworks={artworks} />
	)
}

export default Home