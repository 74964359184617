import React, { useEffect, useState } from 'react'

const Video = ({ src }) => {
	const [video, setVideo] = useState('')
	useEffect(() => {
		let id = ''
		if (src.includes('watch?v=')) {
			id = src.split('watch?v=')[1]
		} else if (src.includes('youtu.be')) {
			id = src.split('youtu.be/')[1]
		}
		setVideo(`https://www.youtube.com/embed/${id}`)
	}, [])
	return (
		<div className="video-wrapper">
			<iframe title="YouTube video" src={video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
		</div>
	)
}

export default Video