import React, { useState } from 'react'
import EnlargeIcon from '../Icons/EnlargeIcon'
import PlayIcon from '../Icons/PlayIcon'
import Lightbox from './Lightbox'

const Thumbnail = ({ media, size }) => {
	const [showLightbox, setShowLightbox] = useState(false)

	const openLightbox = () => {
		setShowLightbox(true)
	}

	const getSize = (thumbnail, size) => {
		let ext = thumbnail.url.split(/[#?]/)[0].split('.').pop().trim()
		if (ext === 'gif') {
			return thumbnail.url
		}
		return thumbnail.sizes[size]
	}

	return (
		<div className="media-image">
			<img src={getSize(media._thumbnail, size)} alt={media._thumbnail.alt} />
			<div className="enlarge-media" onClick={openLightbox}>
				{media._video_url ? <PlayIcon fill="#fff" /> : <EnlargeIcon />}
			</div>
			{showLightbox ? <Lightbox media={media} showLightbox={showLightbox} setShowLightbox={setShowLightbox} /> : null}
		</div>
	)
}

export default Thumbnail