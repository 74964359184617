import React, { useContext, useEffect, useState, useRef } from 'react'
import AppContext from '../../context'
import { Link, useHistory } from 'react-router-dom'
import { gsap, Back, Power3 } from 'gsap'
import debounce from '../../utils/debounce'
import './artworks.scss'

const GridView = props => {
	const { artworks } = props
	const { layout, setLayout, dotsLoaded, setDotsLoaded } = useContext(AppContext)
	const [artworkSize, setArtworkSize] = useState(Math.ceil(0.2175 * window.innerWidth))
	const [gutter, setGutter] = useState(6)
	const history = useHistory()
	const gridRef = useRef(null)

	const dotNodes = []
	const setDotRef = ref => {
		if (ref) {
			dotNodes.push(ref)
		}
	}

	const cols = []
	if (artworks && artworks.length) {
		artworks.sort((a, b) => parseInt(a.id) > parseInt(b.id) ? 1 : -1)
		cols[0] = artworks.slice(0, 33)
		cols[1] = artworks.slice(33, 67)
		cols[2] = artworks.slice(67, 100)
	}

	// Run gsap animation
	useEffect(() => {
		gridRef.current.focus()
		if (dotsLoaded) {
			gsap.from(dotNodes, { scale: 0, opacity: 0, ease: Back.easeInOut })
		}
	}, [dotsLoaded])

	const counter = useRef(0)
	const imageLoaded = () => {
		counter.current += 1
		if (counter.current >= artworks.length) {
			setDotsLoaded(true)
		}
	}

	// Change dot size on resize
	useEffect(() => {
		window.addEventListener('resize', changeDotSize)
		return () => {
			window.addEventListener('resize', changeDotSize)
		}
	}, [])

	const changeDotSize = debounce(function handleResize() {
		if (window.innerWidth < 640) setArtworkSize(0.2875 * window.innerWidth)
		else setArtworkSize(0.2175 * window.innerWidth)
	})

	const goToArtwork = (slug, id) => {
		let index = artworks.map(art => art.id).indexOf(id)
		dotNodes[index].style.zIndex = 3
		gsap.to(dotNodes[index], 2, {
			scale: 20,
			backgroundColor: '#a79f9d',
			ease: Power3.easeInOut, 
			onStart: () => {
				dotNodes[index].childNodes[0].style.opacity = 0
			},
			onComplete: () => {
				history.push(`/artworks/${slug}`)
			}
		})
	}

	return (
		<div className="all-artworks artworks-grid" ref={gridRef} tabIndex="2">
			{cols.map((col, colIndex) => (
				<div className="grid-column" key={colIndex}>
					{col.map((artwork, index) => {
						let thumbnail = artwork.better_featured_image ? artwork.better_featured_image.media_details.sizes.medium.source_url : ''
						return (
							<div
								onClick={() => goToArtwork(artwork.slug, artwork.id)}
								key={index}
								ref={setDotRef}
								className="artwork-dot"
								data-col={index}
								style={{
									display: dotsLoaded ? 'block' : 'none',
									width: artworkSize, 
									height: artworkSize,
									margin: `${gutter * 2}px ${gutter}px`,
								}}
							>
								<img src={thumbnail} onLoad={imageLoaded} />
							</div>
						)
					})}
				</div>
			))}
		</div>
	)
}

export default GridView