import React, { useContext, useEffect, useState, useRef } from 'react'
import AppContext from '../../context'
import { Link, useHistory } from 'react-router-dom'
import { gsap, Back, Power3 } from 'gsap'
import debounce from '../../utils/debounce'
import './artworks.scss'

const GridView = props => {
	const { artworks } = props
	const { layout, setLayout, dotsLoaded, setDotsLoaded, mainRef } = useContext(AppContext)
	const [artworkSize, setArtworkSize] = useState(115)
	const [gutter, setGutter] = useState(6)
	const history = useHistory()
	const rainbowRef = useRef(null)

	const dotNodes = []
	const setDotRef = ref => {
		if (ref) {
			dotNodes.push(ref)
		}
	}

	const rows = []
	if (artworks && artworks.length) {
		artworks.sort((a, b) => parseInt(a.acf.rainbow_sorting) > parseInt(b.acf.rainbow_sorting) ? 1 : -1)
		/*if (window.innerWidth < 640) {
			rows[0] = artworks.slice(0, 12)
			rows[1] = artworks.slice(12, 24)
			rows[2] = artworks.slice(24, 36)
			rows[3] = artworks.slice(36, 48)
			rows[4] = artworks.slice(48, 61)
			rows[5] = artworks.slice(61, 74)
			rows[6] = artworks.slice(74, 87)
			rows[7] = artworks.slice(87, 100)
		} else {*/
			rows[0] = artworks.slice(0, 25)
			rows[1] = artworks.slice(25, 50)
			rows[2] = artworks.slice(50, 75)
			rows[3] = artworks.slice(75, 100)
		/*}*/
	}

	// Run flip function
	useEffect(() => {
		rainbowRef.current.focus()
		if (dotsLoaded) {
			gsap.to(dotNodes, { scale: 1, opacity: 1, ease: Back.easeInOut })
		}
	}, [dotsLoaded])

	// Change dot size on resize
	useEffect(() => {
		changeDotSize()
		window.addEventListener('resize', changeDotSize)
		window.addEventListener('wheel', scrollHandler, true)
		return () => {
			window.removeEventListener('resize', changeDotSize)
			window.removeEventListener('wheel', scrollHandler)
		}
	}, [])

	const scrollHandler = e => {
		mainRef.current.scrollLeft += e.deltaY
	}

	const changeDotSize = debounce(function handleResize() {
		if (window.innerWidth < 640) setArtworkSize(95)
		else setArtworkSize(115)
	})

	const counter = useRef(0)
	const imageLoaded = () => {
		counter.current += 1
		if (counter.current >= artworks.length) {
			setDotsLoaded(true)
		}
	}

	const goToArtwork = (slug, id) => {
		let index = artworks.map(art => art.id).indexOf(id)
		dotNodes[index].style.zIndex = 3
		gsap.to(dotNodes[index], 2, {
			scale: 20,
			backgroundColor: '#a79f9d',
			ease: Power3.easeInOut,
			onStart: () => {
				dotNodes[index].childNodes[0].style.opacity = 0
			},
			onComplete: () => {
				history.push(`/artworks/${slug}`)
			}
		})
	}

	return (
		<div className="all-artworks artworks-rainbow" ref={rainbowRef} tabIndex="2">
			{rows.map((row, rowIndex) => (
				<div className="grid-row" key={rowIndex} style={{
					width: (artworkSize * 25) + (gutter * 2 * 25),
				}}>
					{row.map((artwork, index) => {
						let thumbnail = artwork.better_featured_image ? artwork.better_featured_image.media_details.sizes.medium.source_url : ''
						return (
							<div
								onClick={() => goToArtwork(artwork.slug, artwork.id)}
								key={index}
								ref={setDotRef}
								className="artwork-dot"
								data-col={index}
								data-sort={artwork.acf.rainbow_sorting}
								style={{
									display: dotsLoaded ? 'block' : 'none',
									width: artworkSize,
									height: artworkSize,
									margin: gutter,
									opacity: 0,
									transform: 'scale(0)'
								}}
							>
								<img src={thumbnail} onLoad={imageLoaded} />
							</div>
						)
					})}
				</div>
			))}
		</div>
	)
}

export default GridView
