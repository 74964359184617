import React from 'react'
import SearchIcon from '../Icons/SearchIcon'

const SearchBar = ({ submitSearch, setKeyword, searchInputRef, isSearching, setIsSearching }) => {

	const changeHandler = e => {
		setKeyword(e.target.value.toLowerCase().trim())
		if (isSearching) setIsSearching(false)
	}

	return (
		<form className="search-form" onSubmit={submitSearch}>
			<input ref={searchInputRef} type="text" name="search" autoComplete="off" placeholder="Type keyword(s) and press enter to search" onChange={changeHandler} />
			<button type="submit" className="search-button">
				<SearchIcon fill="#fff" />
			</button>
		</form>
	)
}

export default SearchBar