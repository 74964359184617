import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AppContext from '../../context'
import axios from 'axios'

const NextArtist = props => {
	const [nextPost, setNextPost] = useState(null)
	const { id, artworkRef } = props
	const history = useHistory()
	const { mainRef } = useContext(AppContext)

	useEffect(() => {
		if (id !== 0) {
			axios.get(`https://heartlands.pluralartmag.com/wp-json/wp/v2/artworks/${id}`).then(res => {
				setNextPost(res.data)
			})
		}
	}, [id])

	const goToNextArtwork = () => {
		artworkRef.current.style.opacity = 0
		setTimeout(() => {
			mainRef.current.scrollTo(0, 0)
			history.push(`/artworks/${nextPost.slug}`)
		}, 600)
	}

	return (
		<>
			{nextPost ?
				<section className="artwork-next-artist">
					<span className="artwork-label">Next artist</span>
					<h2 onClick={goToNextArtwork} className="next-artist-name">{nextPost.acf.artist_name}</h2>
				</section>
			: ''}
		</>
	)
}

export default NextArtist