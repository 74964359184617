import React, { useContext, useEffect, useState, useRef } from 'react'
import AppContext from '../../context'
import { Link, useHistory } from 'react-router-dom'
import { gsap, Back, Power3 } from 'gsap'
import './artworks.scss'

const Rainbow = props => {
	const { artwork } = props
	const { layout, setLayout, dotsLoaded, setDotsLoaded } = useContext(AppContext)
	const [gutter, setGutter] = useState(6)
	const history = useHistory()

	let dotNode = null
	const gridBoxes = []
	let baseParent = null
	const setDotRef = ref => {
		if (ref) {
			dotNode = ref
		}
	}

	// Run flip function
	useEffect(() => {
		if (artwork) {
			gsap.from(dotNode, { scale: 0, opacity: 0, ease: Back.easeInOut })
		}
	}, [artwork])

	const counter = useRef(0)
	const imageLoaded = () => {
		counter.current += 1
		if (counter.current === 1) {
			setDotsLoaded(true)
		}
	}

	const goToArtwork = slug => {
		gsap.to(dotNode, 2, { 
			scale: 3,
			backgroundColor: '#a79f9d',
			ease: Power3.easeInOut, 
			onStart: () => {
				dotNode.childNodes[0].style.opacity = 0
			},
			onComplete: () => {
				history.push(`/artworks/${slug}`)
			}
		})
	}

	const thumbnail = artwork && artwork.better_featured_image ? artwork.better_featured_image.media_details.sizes.medium.source_url : ''

	return (
		<div className="all-artworks artworks-jackpot">
			<div className="container">
				{artwork ? 
					<div
						onClick={() => goToArtwork(artwork.slug)}
						key={artwork.id}
						ref={setDotRef}
						className="artwork-dot"
						style={{
							display: dotsLoaded ? 'block' : 'none',
						}}
					>
						<img src={thumbnail} onLoad={imageLoaded} />
					</div>
				: ''}
			</div>
		</div>
	)
}

export default Rainbow