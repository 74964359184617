import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../Icons/Logo'
import './header.scss'

import LayoutSwitcher from './LayoutSwitcher'

const Header = () => {
	const location = useLocation()

	return (
		<header>
			<div className="container">
				<div className="header-wrapper">
					<Link to="/" className="logo">
						<Logo fill="#fff" />
					</Link>
					<LayoutSwitcher />
				</div>
			</div>
		</header>
	)
}

export default Header