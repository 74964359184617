import React, { useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { BrowserRouter } from 'react-router-dom'
import AppContext from '../context'
import 'sanitize.css'
import './App.scss'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import RouteTransitions from './RouteTransitions'

const App = () => {

	const [dotsLoaded, setDotsLoaded] = useState(false)
	const [showSearch, setShowSearch] = useState(false)
	const [artworks, setArtworks] = useState([])
	const [layout, setLayout] = useState('map')
	let mainRef = useRef(null)
	let innerMainRef = useRef(null)
	
	return (
		<AppContext.Provider value={{
			dotsLoaded,
			setDotsLoaded,
			layout,
			setLayout,
			showSearch,
			setShowSearch,
			artworks,
			setArtworks,
			mainRef,
			innerMainRef
		}}>
			<BrowserRouter>
				<CSSTransition mountOnEnter={true} unMountOnExit={true} in={dotsLoaded} timeout={1000} classNames="fade-in">
					<Header />
				</CSSTransition>
				<RouteTransitions />
				<CSSTransition mountOnEnter={true} unMountOnExit={true} in={dotsLoaded} timeout={1000} classNames="fade-in">
					<Footer />
				</CSSTransition>
			</BrowserRouter>
		</AppContext.Provider>
	)
}

export default App